/* .MuiCardHeader-title{
    font-size:10rem;
} */

/* .MuiTabs-title{
    font-size:10rem;

} */





.tabsMedia{
  /* margin-top: 1600px; */
  /* margin-top:600px !important; */

  /* display:none !important; */

  /* display:'none' !important; */

}
  









/* .tabsMedia{display:none !important;} */

/* @media all and (min-width: 700px) and (max-height:500px) {} */








/* @media all and (max-width: 650px) and (min-height:296px) {.tabsMedia{margin-top:350px !important;}} */
/* @media all and (max-width: 650px) and (min-height:396px) {.tabsMedia{margin-top:300px !important;}} */
/* @media all and (max-width: 650px) and (min-height:596px) {.tabsMedia{margin-top:250px !important;}} */



/* @media all and (min-width: 1260px) and (max-height:296px) {.tabsMedia{margin-top:500px !important;}} */

/* @media all and (min-width: 1250px) and (max-height:500px) {.tabsMedia{visibility:visible !important;}} */
/* @media all and (min-width: 992px) {.tabsMedia{visibility:visible !important;}} */
/* @media all and (min-width: 1200px) {.tabsMedia{visibility:hidden !important;}} */

  
/* .loopedCard{max-width: 945px !important;} */

/*  */



@media (min-width: 360px) {
  .tabFix{
    /* display: none !important */
    margin-left: 10px !important;
  }
}



@media (min-width: 750px) {
  .tabFix{
    /* display: none !important */
    margin-left: 650px !important;
  }
}



@media (min-width: 890px) {
  .tabFix{
    /* display: none !important */
    margin-left: 600px !important;
  }
}

@media (min-width: 1100px) {
  .tabFix{
    /* display: none !important */
    margin-left: 280px !important;
  }
}

@media (min-width: 1200px) {
  .tabFix{
    /* display: none !important */
    margin-left: 280px !important;
  }
}
/* @media (min-width: 550px) {
  .marginTabFix {
  margin-left:20px
  }
} */

/* @media (min-width: 760px) {
  .marginTabFix {
  margin-left:870px
  }
} */

/* @media (min-width: 900px) {
  .marginTabFix {
  margin-left:840px
  }
} */

/* @media (min-width: 1100px) {
  .marginTabFix {
  margin-left:580px
  }
} */

/* @media (min-width: 1240px) {
  .marginTabFix {
  margin-left:340px
  }
} */



@media all and (max-width: 450px) {
  /* .OurDataPodcastOne{min-width:350px;} */
  /* .StanfordSiteScreenshot{max-width:400px !important;} */
  .loopedCard{max-width: 345px !important;}
  .MeetCodexVideo{ min-width:260px !important;}

}
@media all and (min-width: 450px) {
  /* .OurDataPodcastOne{min-width:350px;} */
  /* .StanfordSiteScreenshot{max-width:400px !important;} */
  .loopedCard{max-width: 450px !important;}
  .MeetCodexVideo{ min-width:400px !important; min-height:225px  }

}

@media all and (min-width: 700px) {
  .loopedCard{max-width: 645px !important;}

  .OurDataPodcastOne{min-width:620px;}
  .MeetCodexVideo{ min-width:620px !important; min-height:349px  }

  
}

@media all and (min-width: 900px) {
  .loopedCard{max-width: 845px !important;}
  .MeetCodexVideo{ min-width:770px !important; min-height:433px  }


}

/* .MeetCodexVideo{ min-width:260px !important;} */

























