.App{
  overflow-x: hidden;
  
}



/* @media () {

} */

/* @media (min-width: 360px) {
  .marginTabFix {
  margin-left:10px
  }
}

@media (min-width: 550px) {
  .marginTabFix {
  margin-left:20px
  }
}

@media (min-width: 760px) {
  .marginTabFix {
  margin-left:870px
  }
}

@media (min-width: 900px) {
  .marginTabFix {
  margin-left:840px
  }
}

@media (min-width: 1100px) {
  .marginTabFix {
  margin-left:580px
  }
}

@media (min-width: 1240px) {
  .marginTabFix {
  margin-left:340px
  }
} */

/* @media (min-width: 1200px) {
  .marginTabFix {
  margin-left:310px
  }
} */


@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma'), url(./assets/fonts/Axiforma-Regular.ttf) format('truetype');
}



@media (min-width: 768px) {
  .MuiTabs-flexContainer {
    justify-content: center;
  }

  .MuiTabs-scroller{flex-grow: 0}
}



@media all and (max-width: 800px) {
  .TimelineTitle{
    font-size: 1.7rem !important;
    margin-top: 18px !important;
    /* margin-bottom:27px !important; */
        margin-bottom:3% !important;


  }
  .titlePaperClass{
    margin-left: 0px !important;
  }

  .titleBooks{
    padding-top: 40 !important;
    font-family: 'Montserrat', sans-serif !important;
    /* font-size: 2rem !important; */
    font-size: 1.5rem !important;
  
    margin-bottom: -5px !important;
  }

}



@media all and (min-width: 800px) {
  .titleBooks{
    font-size: 2rem !important;
  }
  .TimelineTitle{
    /* text-transform: uppercase !important; */
    /* font-weight: bold !important; */
    margin-top: 20px !important;
    margin-bottom: 3% !important;

    /* padding: 23px !important; */
    font-size: 2.5rem !important;
  }
}



@media all and (max-width: 400px) {
  .EducationBox{
    margin-left:72px ;
    /* width:100%; */
  
  }
}


@media all and (min-width: 400px) {
  .EducationBox{
    margin-left:40px ;
    /* width:100%; */
  
  }
}

@media all and (min-width: 750px) {
  .EducationBox{
    margin-left:40px ;
    /* width:100%; */

  }
  .TimelineTitle{
    /* text-transform: uppercase !important; */
    /* font-weight: bold !important; */
    margin-top: 57px !important;
    margin-bottom: 6% !important;

    /* padding: 23px !important; */
    font-size: 2.7rem !important;
  }

}
@media all and (min-width: 900px) {

  .TimelineTitle{
    /* text-transform: uppercase !important; */
    /* font-weight: bold !important; */
    margin-top: 44px !important;
    margin-bottom: 3% !important;

    /* padding: 23px !important; */
    font-size: 3rem !important;
  }

}



.TimelineTitle{
  text-transform: uppercase !important;
  font-weight: bold !important;
  /* margin-top: 20px !important; */
  /* padding: 23px !important; */
  /* font-size: 3rem !important; */
}


.introRoot{
  background: url(assets/images/hero.jpg) no-repeat center center fixed; 
  background-position: center;
  background-size: cover;
  background-attachment:fixed;
  height: 85vh;
}

.introBackground{

    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;
    
    /* Set up proportionate scaling */
    /* width: 100%; */
    /* height: auto; */
    height:85vh;
    
    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;

}

@media screen and (max-width: 1024px) { /* Specific to this particular image */
  .introBackground {
    left: 50%;
    margin-left: -512px;   /* 50% */
  }
}